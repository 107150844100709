import React from "react"
import { Box, Heading, Grid, Flex, Container, Link, Text } from "theme-ui"
import { Image } from "react-datocms"
import { i18nContext } from "../../context/i18nContext"

const DocumentPreview = ({ document }) => {
  return (
    <i18nContext.Consumer>
      {t => (
        <Box sx={{ backgroundColor: "primary" }} key={document.id}>
          <Container sx={{ padding: [3, 4] }}>
            <Grid columns={[1, 2]} gap={[3, 4]}>
              <Flex sx={{ flexDirection: "column", justifyContent: "center" }}>
                {document.channel && (
                  <Box
                    variant="links.badge.smallCategory"
                    sx={{
                      backgroundColor: document.channel.pageLink.slug,
                      display: "inline-block",
                      width: "fit-content",
                      mb: [4],
                    }}
                  >
                    {document.channel.pageLink.title}
                  </Box>
                )}
                <Heading
                  as="h3"
                  variant="h2"
                  sx={{ color: "dark", mt: [0], mb: [2] }}
                >
                  {document.title}
                </Heading>
                <Text sx={{ mb: [4], fontSize: [1] }}>
                  {document.subtitle && (
                    <Text
                      sx={{
                        mt: [2],
                        textTransform: "uppercase",
                        fontSize: [1],
                        fontWeight: "bold",
                      }}
                      mt={[2]}
                    >
                      {document.subtitle} -{" "}
                    </Text>
                  )}
                  {new Date(document._firstPublishedAt)
                    .toLocaleDateString()
                    .replaceAll("/", ".")}
                </Text>
                <Link
                  href={document.documents[0].url}
                  sx={{
                    color: "light",
                    display: "inline-flex",
                    textDecoration: "none",
                  }}
                  target="_blank"
                >
                  <Box
                    sx={{
                      backgroundColor: "dark",
                      display: "inline-block",
                      px: [4],
                      py: [3],
                      borderRadius: "full",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontSize: [1],
                    }}
                  >
                    {t.downloadDocument}
                  </Box>
                </Link>
              </Flex>
              <Flex sx={{ justifyContent: "flex-end" }}>
                {document.thumbnail && (
                  <Image data={document.thumbnail.responsiveImage} />
                )}
              </Flex>
            </Grid>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default DocumentPreview
