import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Image } from "react-datocms"
import { Box, Container, Heading, Text, Flex, Link, Grid } from "theme-ui"
import { UserContext } from "../context/userContext"
import { gql } from "graphql-request"
import datocms from "../services/datocms"
import VideoPlaylist from "./blocks/videoPlaylist"
import DocumentPreview from "./blocks/documentPreview"
import { i18nContext } from "../context/i18nContext"
import supabase from "../services/supabase"

const Dashboard = () => {
  const user = useContext(UserContext)
  const [page, setPage] = useState(null)
  const locale = "en"

  useEffect(() => {
    async function fetchContent() {
      try {
        const query = gql`
          query getPrivateArea($locale: SiteLocale) {
            privateArea(locale: $locale) {
              id
              title
              documents {
                ... on TitleAndSubtitleRecord {
                  id
                  title
                  subtitle
                  _modelApiKey
                }
                ... on DocumentCollectionRecord {
                  id
                  title
                  _modelApiKey
                  documents {
                    id
                    title
                    subtitle
                    _firstPublishedAt
                    thumbnail {
                      responsiveImage {
                        alt
                        base64
                        bgColor
                        title
                        aspectRatio
                        height
                        sizes
                        src
                        srcSet
                        webpSrcSet
                        width
                      }
                    }
                    channel {
                      id
                      pageLink {
                        id
                        title
                        slug
                      }
                    }
                    documents {
                      url
                    }
                  }
                }
              }
              playlists {
                ... on TitleAndSubtitleRecord {
                  id
                  title
                  subtitle
                  _modelApiKey
                }
                ... on VideoPlaylistRecord {
                  id
                  title
                  _modelApiKey
                  videos {
                    slug
                    title
                    duration
                    _firstPublishedAt
                    _allSlugLocales {
                      locale
                      value
                    }
                    category {
                      id
                      title
                      slug
                      channel {
                        id
                        pageLink {
                          id
                          slug
                        }
                      }
                    }
                    thumbnail {
                      responsiveImage {
                        alt
                        base64
                        bgColor
                        title
                        aspectRatio
                        height
                        sizes
                        src
                        srcSet
                        webpSrcSet
                        width
                      }
                    }
                    speaker {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        `

        const variables = {
          locale: locale,
        }

        const data = await datocms.request(query, variables)
        // console.log(data)
        setPage(data.privateArea)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    if (user && user.user) {
      fetchContent()
    }
  }, [user])

  if (!user.user) {
    navigate("/login/", {
      replace: true,
    })
    return null
  } else {
    return (
      <i18nContext.Consumer>
        {t => (
          <Box>
            <Container>
              <Flex
                sx={{
                  justifyContent: "space-between",
                  mt: [3, 4, 4],
                  mb: [3, 4, 6],
                }}
              >
                <Text>
                  {t.welcome}{" "}
                  <Text sx={{ fontWeight: "bold" }}>
                    {user.user.user_metadata.full_name || user.user.email}
                  </Text>
                </Text>
                <Text
                  sx={{
                    textDecoration: "underline",
                    "&:hover": { color: "primary", cursor: "pointer" },
                  }}
                  onClick={() => supabase.auth.signOut()}
                >
                  {t.signOut}
                </Text>
              </Flex>
              {page && page.documents && (
                <>
                  {page.documents.map(block => (
                    <Box as="section" key={block.id} sx={{ mb: ["4rem"] }}>
                      {block._modelApiKey === "title_and_subtitle" && (
                        <Box>
                          <Heading as="h2" variant="h1" sx={{ color: "dark" }}>
                            {block.title}
                          </Heading>
                          <Box
                            dangerouslySetInnerHTML={{ __html: block.subtitle }}
                            sx={{ fontSize: [5] }}
                          />
                        </Box>
                      )}
                      {block._modelApiKey === "document_collection" && (
                        <Box>
                          <DocumentPreview document={block.documents[0]} />

                          {/* Render the rest of the documents in a grid */}
                          <Grid
                            columns={[
                              "1fr",
                              "1fr",
                              "1fr 1fr ",
                              "1fr 1fr 1fr 1fr",
                            ]}
                            mt={[4]}
                          >
                            {block.documents.slice(1).map(document => (
                              <Flex
                                sx={{
                                  flexDirection: "column",
                                  justifyContent: ["start"],
                                  alignItems: "center",
                                  textAlign: "center",
                                  gap: [2],
                                  border: "1px solid black",
                                  minHeight: "300px",
                                }}
                              >
                                <Box sx={{ p: 0 }}>
                                  {document.thumbnail && (
                                    <Image
                                      data={document.thumbnail.responsiveImage}
                                    />
                                  )}
                                </Box>
                                {document.channel && (
                                  <Box
                                    variant="links.badge.smallCategory"
                                    sx={{
                                      backgroundColor:
                                        document.channel.pageLink.slug,
                                      display: "inline-block",
                                      width: "fit-content",
                                      mb: [4],
                                    }}
                                  >
                                    {document.channel.pageLink.title}
                                  </Box>
                                )}
                                <Heading
                                  as="h3"
                                  variant="h3"
                                  sx={{
                                    color: "dark",
                                    mt: ["0"],
                                    mb: [2],
                                    fontSize: [3, 3, 3, 4],
                                  }}
                                >
                                  {document.title}
                                </Heading>
                                <Text sx={{ mb: [1], fontSize: [0] }}>
                                  {document.subtitle && (
                                    <Text
                                      sx={{
                                        mt: [2],
                                        textTransform: "uppercase",
                                        fontSize: [1, 1, 0, 1],
                                        fontWeight: "bold",
                                      }}
                                      mt={[2]}
                                    >
                                      {document.subtitle} -{" "}
                                    </Text>
                                  )}
                                  {new Date(document._firstPublishedAt)
                                    .toLocaleDateString()
                                    .replaceAll("/", ".")}
                                </Text>
                                <Link
                                  href={document.documents[0].url}
                                  sx={{
                                    color: "light",
                                    display: "inline-flex",
                                    textDecoration: "none",
                                  }}
                                  target="_blank"
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: "dark",
                                      display: "inline-block",
                                      px: [2, 3, 3, 4],
                                      py: [2, 2, 2, 3],
                                      mb: 3,
                                      borderRadius: "full",
                                      fontWeight: "bold",
                                      textTransform: "uppercase",
                                      fontSize: [0],
                                    }}
                                  >
                                    {t.downloadDocument}
                                  </Box>
                                </Link>
                              </Flex>
                            ))}
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  ))}
                </>
              )}
              {page && page.playlists && (
                <>
                  {page.playlists.map(block => (
                    <Box as="section" key={block.id} sx={{ mb: ["4rem"] }}>
                      {block._modelApiKey === "video_playlist" && (
                        <VideoPlaylist
                          videos={block.videos}
                          title={block.title}
                          locale={locale}
                        />
                      )}
                      {block._modelApiKey === "title_and_subtitle" && (
                        <Box>
                          <Heading as="h2" variant="h1" sx={{ color: "dark" }}>
                            {block.title}
                          </Heading>
                          <Box
                            dangerouslySetInnerHTML={{ __html: block.subtitle }}
                            sx={{ fontSize: [5] }}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </Container>
          </Box>
        )}
      </i18nContext.Consumer>
    )
  }
}

export default Dashboard
