import React from "react"
import { Router } from "@reach/router"
import Layout from "../../components/layout"
import Login from "../login"
import Dashboard from "../../components/dashboard"

const PrivateAreaPage = () => {
  return (
    <Layout
      i18nPaths={[
        {
          locale: "en",
          value: "/",
          menuType: "home",
        },
      ]}
      locale={"en"}
      pageSlug={"home"}
    >
      <Router basepath={`/private`}>
        <Dashboard path="/dashboard" />
      </Router>
    </Layout>
  )
}

export default PrivateAreaPage
