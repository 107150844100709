import React from "react"
import { Box, Heading, Grid, Text, Flex, Image } from "theme-ui"
import VideoThumb from "../videoThumb"
import ArrowRightYellow from "../../images/arrow-right-yellow.svg"

const VideoPlaylist = ({ title, videos, locale }) => {
  return (
    <Grid columns={[1]} sx={{ mt: [4], mb: [4], pb: [7], borderBottom: "1px solid", borderColor: "dark" }} >
      <Flex sx={{ flexDirection: "column", alignItems: "flex-start" }}>
        <Flex sx={{ justifyContent: "center" }}>
          <Image src={ArrowRightYellow} sx={{ mr: [2] }} />
          <Text
            sx={{
              textTransform: "uppercase",
              color: "primary",
              fontWeight: "bold",
            }}
          >
            Playlist
          </Text>
        </Flex>
        <Heading as="h2" variant="h2" sx={{ color: "dark", mt: [2] }}>
          {title}
        </Heading>
      </Flex>
      <Grid columns={[1, 2, 3, 4]}>
        {videos.map((video, index) => (
          <Box>
            <Flex
              sx={{
                display: "inline-flex",
                borderRadius: "full",
                border: "1px solid",
                borderColor: "dark",
                fontSize: [0],
                minWidth: "25px",
                minHeight: "25px",
                justifyContent: "center",
                alignItems: "center",
                mb: [2],
              }}
            >
              <Text
                as="p"
                sx={{
                  fontSize: [0],
                }}
              >
                0{index + 1}
              </Text>
            </Flex>
            <VideoThumb video={video} locale={locale} />
          </Box>
        ))}
      </Grid>
    </Grid>
  )
}

export default VideoPlaylist
